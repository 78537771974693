<script lang="ts">
  import { page } from '$app/stores'
  import { setupIntercomInfo } from '$lib/authentication/result'
  import chatLoader from '$lib/chatloader/context'
  import SignInForm from '$lib/components/auth/SignInForm.svelte'
  import GoogleTagManager from '$lib/components/GoogleTagManager.svelte'
  import Modals from '$lib/components/modals/modals.svelte'
  import Navigation from '$lib/components/Navigation.svelte'
  import ContentFooter from '$lib/components/shared/ContentFooter.svelte'
  import SimpleAnalytics from '$lib/components/shared/SimpleAnalytics.svelte'
  import Spinner from '$lib/components/Spinner.svelte'
  import YoloAuth from '$lib/components/YoloAuth.svelte'
  import { normalizedClient } from '$lib/graphql/makeNormalizedClient'
  import state from '$lib/stores/auth-state'
  import { loadFreatureFlags } from '$lib/stores/feature'
  import { setContextClient } from '@urql/svelte'
  import config from 'src/config'
  import { initTracking } from 'src/utils/track'
  import { onMount } from 'svelte'
  import SvelteSeo from 'svelte-seo'
  import '../../app.css'

  $: requiresAuthentication = $page.data.requiresAuthentication ?? false
  $: signedIn = $state.signedIn
  $: authDidInitialize = $state.authDidInitialize

  setContextClient(normalizedClient)
  onMount(initTracking)
  onMount(loadFreatureFlags)
  onMount(setupIntercomInfo)
  onMount(
    chatLoader.init({
      provider: 'intercom',
      providerKey: config.intercomAppId,
      loadWhileIdle: true,
      idlePeriod: 10e3,
    }),
  )
  // afterNavigate(trackRouteChange)
</script>

<svelte:head>
  <link rel="shortcut icon" href="/favicon.ico" />
  <meta name="theme-color" content="#FC355C" />
  <title>{$page.data.title}</title>
  <meta name="description" content={$page.data.description} />
</svelte:head>

{#if requiresAuthentication && !authDidInitialize}
  <main class="min-h-screen bg-white">
    <Navigation />
    <div class="flex items-center justify-center min-h-screen text-center text-black">
      <Spinner size="md" />
    </div>
  </main>
{:else if requiresAuthentication && !signedIn}
  <nav class="px-6 py-4 border-b-1">
    <div class="flex items-center leading-none">
      <img src="/airheart-logo-full-new.svg" height={32} width={128} alt="Airheart Logo" />
    </div>
  </nav>
  <div class="flex items-center justify-center min-h-screen">
    <div class="w-[420px] px-4 sm:px-0">
      <SignInForm>
        <h3 class="py-2 mb-4 text-2xl font-medium leading-8 tracking-tight text-gray-900 sm:mt-8">
          Sign in to Airheart
        </h3>
      </SignInForm>
    </div>
  </div>
{:else}
  <Navigation />

  <SvelteSeo
    title={$page?.data?.title ?? 'Airheart'}
    jsonLd={{
      '@type': 'Corporation',
      logo: 'https://airheart.com/airheart-mark.svg',
      url: 'https://airheart.com',
      name: 'Airheart, Inc.',
      sameAs: [
        'https://twitter.com/AirheartInc',
        'https://instagram.com/airheartinc',
        'https://www.linkedin.com/company/airheartinc',
        'https://www.facebook.com/AirheartInc',
      ],
    }}
  />

  <main
    aria-labelledby="main page"
    class="grid w-full min-h-screen grid-cols-12 px-0 mx-auto bg-white gap-x-4 lg:gap-x-12 sm:my-4 2xl:my-8"
  >
    <slot />
  </main>
  <ContentFooter />
{/if}

<Modals />
<YoloAuth />
<SimpleAnalytics />
<GoogleTagManager />
